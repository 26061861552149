export const APP_CONFIG = {
    // basename: only at build time to set, and Don't add '/' at end off BASENAME for breadcrumbs, also Don't put only '/' use blank('') instead,
    // like '/berry-material-react/react/default'
    BASENAME: '/',
    DEFAULT_PATH: '/',
    FONT_FAMILY: `'Roboto', sans-serif`,
    BORDER_RADIUS: 12,
    POST_PER_PAGE: 10,
    TRACKING_ID: 'G-6Z345YK3Q7',

    // API_URL: 'http://localhost:10001', // local server
    // API_URL: 'https://dev-api.unicco.vn', // test server
    // API_URL: 'https://api.phimmoizz.live', // prod server

    GG_OAUTH_CLIENT_ID: '606168179421-k8pr3epdpvqe13iha9kadt1g2ukvucc1.apps.googleusercontent.com',

    FB_REDIRECT_URL: 'https://unicco.vn/dang-nhap'
};
